

import usePayment from "@/modules/payments/composables/usePayment";
import {PaymentPayable} from "@/modules/payments/interfaces";
import {nextTick, onMounted, ref, watch} from "vue";
import {baseURL} from "@/api/erpApi";

export default {
    setup() {
        const {paymentForm} = usePayment()
        const depositableType = ref('')
        const depositableId = ref('')

        const select2depositable = {
            ajax: {
                url: () => {
                    if (depositableType.value === 'accountingAccount') {
                        return baseURL + `/accounting_accounts/select2?isLeaf=1`;
                    }

                    if (depositableType.value === 'pettyCash') {
                        return baseURL + `/petty_cash/select2`;
                    }

                    return baseURL + `/${depositableType.value}/select2`;
                }
            }
        }

        onMounted(async () => {
            depositableType.value = paymentForm.value.depositableType

            await nextTick(() => {
                depositableId.value = paymentForm.value.depositableId
            })
        })

        watch(depositableType, val => paymentForm.value.depositableType = val)
        watch(depositableId, val => paymentForm.value.depositableId = val)

        return {
            select2depositable,
            depositableType,
            depositableId,
            total: () => paymentForm.value.paymentPayables.reduce((acc, p: PaymentPayable) => {
                return acc + (+p.total);
            }, 0)
        }
    }
}
