
import {nextTick, onMounted, ref, watch} from "vue";
import {baseURL} from "@/api/erpApi";
import usePayment from "@/modules/payments/composables/usePayment";
import {v4 as uuid} from "uuid";

export default {
    props: ['catalogs', 'mode'],
    setup(props: { mode: string }) {
        const {paymentForm, paymentStarterForm, getStarter} = usePayment();

        const starterType = ref('')
        const starterId = ref('')
        const select2starter = {
            ajax: {
                url: () => {
                    if (starterType.value === 'purchase_invoice') {
                        return baseURL + `/purchase_invoices/select2`;
                    } else if (starterType.value === 'transfer') {
                        return baseURL + `/petty_cash/cash_transfers/select2`;
                    }

                    return baseURL + `/${starterType.value}s/select2`;
                }
            }
        }

        onMounted(() => {
            starterType.value = paymentForm.value.starterType

            nextTick(() => {
                starterId.value = paymentForm.value.starterId
            })
        })

        // watch(starterType, val => paymentStarterForm.value.starterType = val)
        watch(starterType, val => {
            paymentStarterForm.value.starterType = val
            paymentForm.value.starterType = val
        })
        watch(starterId, async (val, oldVal) => {

            if (false === (val && val !== oldVal.toString() && props.mode === 'CREATE')) {
                return;
            }

            paymentStarterForm.value.starterId = val
            paymentForm.value.starterId = val

            const paymentStarter = await getStarter(paymentStarterForm.value)

            paymentStarterForm.value = {
                ...paymentForm.value, ...{
                    vendorType: paymentStarter.vendorType,
                    depositableType: paymentStarter.depositableType,
                    paymentDate: paymentStarter.paymentDate,
                    vendorId: paymentStarter.vendorId,
                    depositableId: paymentStarter.depositableId,
                    total: paymentStarter.total,
                    paymentPayables: paymentStarter.paymentPayables.map(p => {
                        return {
                            id: uuid(),
                            payableType: p.payableType,
                            payableId: p.payableId,
                            payable: p.payable,
                            total: p.total,
                            paymentId: paymentForm.value.id
                        }
                    }),
                    paymentMethods: paymentStarter.paymentMethods.map(p => {
                        return {
                            id: uuid(),
                            paymentMethod: 'cash',
                            total: p.total,
                            paymentId: paymentForm.value.id
                        }
                    }),
                    starterType: paymentStarter.starterType,
                    starterId: paymentStarter.starterId
                }
            }
        })

        return {
            starterType,
            starterId,
            select2starter
        }
    }
}
