
import {nextTick, onMounted, ref, watch} from "vue";
import usePayment from "@/modules/payments/composables/usePayment";
import usePanel from "@/composables/usePanel";
import {baseURL} from "@/api/erpApi";

export default {
    props: ['catalogs', 'mode'],
    setup() {
        const {paymentForm, paymentStarterForm} = usePayment();
        const {showPanel, togglePanel} = usePanel()

        const paymentDate = ref('')
        const vendorId = ref('')
        const name = ref('')
        const status = ref('')

        const select2vendor = {
            ajax: {
                url: () => {
                    if (paymentForm.value.vendorType === 'user') {
                        return baseURL + `/users/select2`;
                    }

                    return baseURL + `/${paymentForm.value.vendorType}s/select2`;
                }
            }
        }

        onMounted(() => {
            paymentDate.value = paymentForm.value.paymentDate
            vendorId.value = paymentForm.value.vendorId
            status.value = paymentForm.value.status
        })

        watch(paymentDate, val => paymentForm.value.paymentDate = val)
        watch(vendorId, val => paymentForm.value.vendorId = val)
        watch(paymentStarterForm, async (val) => {
            paymentForm.value.vendorType = val.vendorType;
            await nextTick(() => {
                paymentDate.value = val.paymentDate;
                vendorId.value = val.vendorId;
            })
        })

        return {
            showPanel,
            togglePanel,
            select2vendor,
            paymentDate,
            vendorId,
            name,
            status,
            paymentForm,
            vendorLabel: () => paymentForm.value.vendorType === 'user' ? 'Usuario' : 'Proveedor'
        }
    }
}
