
import {onMounted, ref, watch} from "vue";
import usePayment from "@/modules/payments/composables/usePayment";

export default {
    setup() {
        const {paymentForm} = usePayment();
        const paymentMethod = ref('')
        const total = ref(0)
        const observations = ref('')

        onMounted(() => {
            if (paymentForm.value.paymentMethods.length > 0) {
                paymentMethod.value = paymentForm.value.paymentMethods[0].paymentMethod
            }
            total.value = paymentForm.value.total
            observations.value = paymentForm.value.observations
        })

        watch(paymentMethod, val => paymentForm.value.paymentMethods[0].paymentMethod = val)
        watch(total, val => {
            paymentForm.value.paymentMethods[0].total = val
            paymentForm.value.total = val
        })
        watch(observations, val => paymentForm.value.observations = val)

        return {
            paymentMethod,
            total,
            observations
        }
    }
}
