

import usePayment from "@/modules/payments/composables/usePayment";
import {watch} from "vue";
import {PaymentPayable} from "@/modules/payments/interfaces";

export default {
    props: ['mode'],
    setup() {
        const {paymentForm, paymentStarterForm} = usePayment()

        watch(paymentStarterForm, async (val) => {
            paymentForm.value.paymentPayables = val.paymentPayables;
        })

        return {
            paymentForm,
            fixTotal: (payable: PaymentPayable) => {
                if (payable.total > payable.payable.balance) {
                    payable.total = payable.payable.balance
                }
            }
        }
    }
}
