import useAuth from "@/modules/auth/composables/useAuth";
import { Ref, ref } from "vue";
import { v4 as uuid } from "uuid";
import { PaymentForm } from "@/modules/payments/interfaces";
import store from "@/store";
import moment from "moment";

const { companyId } = useAuth();
const defaultValues = (): PaymentForm => {
    const id = uuid();

    return {
        id: id,
        paymentDate: moment().format("DD/MM/YYYY"),
        vendorType: "",
        vendorId: "",
        status: "to_be_approved",
        total: 0,
        depositableType: "accountingAccount",
        depositableId: "",
        paymentMethods: [{ id: uuid(), paymentId: id, paymentMethod: "cash", total: 0 }],
        paymentPayables: [],
        companyId: companyId.value,
        starterType: "",
        starterId: "",
        observations: "",
    };
};

const paymentForm: Ref<PaymentForm> = ref(defaultValues());
const paymentStarterForm: Ref<PaymentForm> = ref(defaultValues());

const usePayment = () => {
    const catalogs = {
        status: [
            { id: "to_be_approved", title: "Por aprobar" },
            { id: "to_apply", title: "Por aplicar" },
            { id: "applied", title: "Aplicado" },
            { id: "canceled", title: "Anulado" },
        ],
    };
    const clearForm = () => {
        paymentForm.value = defaultValues();
        paymentStarterForm.value = defaultValues();
    };

    const createPayment = async (paymentForm: PaymentForm) => {
        return await store.dispatch("payments/createPayment", paymentForm);
    };

    const updatePayment = async (paymentForm: PaymentForm) => {
        return await store.dispatch("payments/updatePayment", paymentForm);
    };

    const getPayment = async (id: string): Promise<PaymentForm> => {
        const { data } = await store.dispatch("payments/getPayment", id);

        return data;
    };

    const getStarter = async (paymentForm: PaymentForm): Promise<PaymentForm> => {
        const { data } = await store.dispatch("payments/getStarter", paymentForm);

        return data;
    };

    return {
        paymentForm,
        paymentStarterForm,
        catalogs,
        clearForm,
        createPayment,
        updatePayment,
        getPayment,
        getStarter,
    };
};

export default usePayment;
